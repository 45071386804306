import { Outlet, Route, Routes } from 'react-router-dom';

import Suspense from '@/component/Suspense';
import lazy from '@/util/lazy';

const PublicOrder = lazy(() => import('./component/PublicOrder/PublicOrder'));
const PublicValidatePurchaseOrder = lazy(
  () => import('./component/PublicValidatePurchaseOrder/PublicValidatePurchaseOrder'),
);

export default function PublicScene() {
  return (
    <Routes>
      <Route
        element={
          <Suspense>
            <Outlet />
          </Suspense>
        }
      >
        <Route path="/purchase-order/:purchaseOrderId" element={<PublicValidatePurchaseOrder />} />
        <Route path="/order/:id/:secret/*" element={<PublicOrder />} />
      </Route>
    </Routes>
  );
}
