import swr, { SWRResponse } from 'swr';

import { axios } from '@/util/axios';
import { toDate } from '@/util/date';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import { ListOrdersQueryInput, listOrdersQueryValidator } from './list-orders.input.ts';
import { ListOrdersOutput } from './list-orders.output.ts';

const path = '/order';

export const listOrders = async (query: ListOrdersQueryInput): Promise<ListOrdersOutput> => {
  try {
    await listOrdersQueryValidator.validate(query);
    const url = getUrl(path, query);
    return await axios.get<ListOrdersOutput>(url).then((res) => ({
      ...res.data,
      docs: res.data.docs.map((d) => ({
        ...d,
        createdAt: toDate(d.createdAt),
        deliveryDate: toDate(d.deliveryDate),
        invoices: d.invoices.map((i) => ({
          ...i,
          invoicedAt: toDate(i.invoicedAt),
        })),
      })),
    }));
  } catch (err) {
    return handleError(path, err, { query });
  }
};

export const useListOrders = (
  query: ListOrdersQueryInput | null,
): SWRResponse<ListOrdersOutput> => {
  const url = query === null ? null : getUrl(path, query);
  return swr<ListOrdersOutput>(url, () => listOrders(query as ListOrdersQueryInput));
};
