import { ResellerRole } from '@/enum';
import { userStore } from '@/store/user';

type Output = (role: ResellerRole) => boolean;

/**
 * Hook personnalisé pour vérifier si le revendeur de l'utilisateur connecté possède un certain rôle.
 * @returns {(role: ResellerRole) => boolean} - Fonction prenant un rôle en paramètre et retournant vrai si le revendeur de l'utilisateur possède ce rôle, sinon faux.
 */
export const useResellerHasRole = (): Output => {
  const roles = userStore((s) => s.reseller?.roles || []);
  return (role: ResellerRole) => roles.includes(role);
};
