import { Paper, Table as MuiTable, TableContainer } from '@mui/material';
import Color from 'color';
import { ComponentProps, ReactNode } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';

import { ChildericColor } from '@/util/theme';

export const StyledTable = styled(MuiTable)`
  tbody tr:nth-child(even) {
    background-color: ${Color(ChildericColor.Secondary).alpha(0.1).toString()};
  }

  tbody tr:hover {
    background-color: ${Color(ChildericColor.Secondary).alpha(0.3).toString()};
  }
`;

type Props = ComponentProps<typeof StyledTable> & { children: ReactNode; elevation?: number };

export const Table = ({ children, elevation, ...props }: Props) => {
  return (
    <TableContainer component={Paper} elevation={elevation}>
      <ScrollContainer>
        <StyledTable size="small" {...props}>
          {children}
        </StyledTable>
      </ScrollContainer>
    </TableContainer>
  );
};
