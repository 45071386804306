import mitt, { Emitter } from 'mitt';

import { GetMeOutput } from '@/api/auth/get-me';

// TODO: Use this emmiter for all events

type Events = {
  NOTIFICATION_SHOW: number;
  NAVIGATION_REDIRECT: string;
  USER_LOGOUT: void;
  USER_UPDATE: GetMeOutput | null;
  XSRF_TOKEN_UPDATE: string | null;
  INVOICE_DELETE: string;
  INVOICE_UPDATE: string;
  INVOICE_CREATE: string;
  INVOICE_UPDATE_TITLE: string;
  INVOICE_VALIDATE: string;
};

const emitter: Emitter<Events> = mitt<Events>();

const EventBus = {
  showNotification: (snack: number) => emitter.emit('NOTIFICATION_SHOW', snack),
  onNotificationShow: (callback: (snack: number) => void) =>
    emitter.on('NOTIFICATION_SHOW', callback),

  redirectTo: (path: string) => emitter.emit('NAVIGATION_REDIRECT', path),
  onRedirect: (callback: (path: string) => void) => emitter.on('NAVIGATION_REDIRECT', callback),

  logoutUser: () => emitter.emit('USER_LOGOUT'),
  onUserLogout: (callback: () => void) => emitter.on('USER_LOGOUT', callback),

  updateUser: (user: GetMeOutput | null) => emitter.emit('USER_UPDATE', user),
  onUserUpdate: (callback: (user: GetMeOutput | null) => void) =>
    emitter.on('USER_UPDATE', callback),

  updateXsrfToken: (token: string | null) => emitter.emit('XSRF_TOKEN_UPDATE', token),
  onXsrfTokenUpdate: (callback: (token: string | null) => void) =>
    emitter.on('XSRF_TOKEN_UPDATE', callback),

  invoiceDelete: (invoiceId: string) => emitter.emit('INVOICE_DELETE', invoiceId),
  onInvoiceDelete: (callback: (invoiceId: string) => void) =>
    emitter.on('INVOICE_DELETE', callback),

  invoiceUpdate: (invoiceId: string) => emitter.emit('INVOICE_UPDATE', invoiceId),
  onInvoiceUpdate: (callback: (invoiceId: string) => void) =>
    emitter.on('INVOICE_UPDATE', callback),

  invoiceUpdateTitle: (invoiceId: string) => emitter.emit('INVOICE_UPDATE_TITLE', invoiceId),
  onInvoiceUpdateTitle: (callback: (invoiceId: string) => void) =>
    emitter.on('INVOICE_UPDATE_TITLE', callback),

  invoiceValidate: (invoiceId: string) => emitter.emit('INVOICE_VALIDATE', invoiceId),
  onInvoiceValidate: (callback: (invoiceId: string) => void) =>
    emitter.on('INVOICE_VALIDATE', callback),

  invoiceCreate: (invoiceId: string) => emitter.emit('INVOICE_CREATE', invoiceId),
  onInvoiceCreate: (callback: (invoiceId: string) => void) =>
    emitter.on('INVOICE_CREATE', callback),

  off: (event: keyof Events, callback: any) => emitter.off(event, callback),
};

export default EventBus;
