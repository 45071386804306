import axios from 'axios';
import { useMemo } from 'react';
import useSWR from 'swr';

import { I18n } from '@/enum';
import { useLang } from '@/hook';

export type CountryIndex = { [index: string]: string };

export function useCountry(lang?: I18n): CountryIndex {
  const fullLang = useLang();

  const _lang = useMemo(() => {
    if (lang) return lang;
    const [l] = fullLang.split('-');
    return l || 'fr';
  }, [fullLang, lang]);

  const { data } = useSWR<CountryIndex>(
    `https://flagcdn.com/${_lang}/codes.json`,
    (url: string) =>
      axios.get(url).then((res) =>
        Object.keys(res.data).reduce(
          (acc, key) => ({
            ...acc,
            [key.toUpperCase()]: res.data[key],
          }),
          {},
        ),
      ),
    // { revalidateOnFocus: false, revalidateOnMount: false, revalidateOnReconnect: false },
  );

  return data || {};
}
