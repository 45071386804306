import { Currency, currencyMap } from '@/enum';

import numeral, { defaultSymbol } from './numeral';

type Props = {
  currency?: Currency;
  force?: boolean;
};

const defaultOptions = { currency: 'EUR', force: false };

export function getSymbol(currency?: Currency): string {
  return currencyMap.get(currency || 'EUR') || defaultSymbol;
}

export function price(value: number, options?: Props): string {
  const { currency, force } = Object.assign(defaultOptions, options);

  const format = force ? '0,0.00 $' : '0,0[.]00 $';

  const symbol = getSymbol(currency);

  return numeral(value).format(format).replace(new RegExp(defaultSymbol), symbol);
}

export function numberToPrice(value: number, currency: Currency, force = false): string {
  return price(value, { currency, force });
}

export function priceToNumber(value: string): number | null {
  return numeral(value).value();
}
