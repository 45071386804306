import { Tabs } from '@mui/material';
import { useState } from 'react';

import Suspense from '@/component/Suspense';
import { ResellerRole, UserRole } from '@/enum';
import { useResellerHasRole, useUserHasSomeRole, useUserIsChilderic } from '@/hook/user';
import lazy from '@/util/lazy';

import { ETab } from './enum';
import { StyledOrderList } from './OrderList.style';
import {
  TabChildericOrder,
  TabDraft,
  TabHistory,
  TabLast,
  TabPayment,
  TabRemember,
  TabRepair,
  TabValidate,
} from './tab';

const OrderListChildericOrder = lazy(() => import('./component/OrderListChildericOrder'));
const OrderListDraft = lazy(() => import('./component/OrderListDraft'));
const OrderListHistory = lazy(() => import('./component/OrderListHistory'));
const OrderListLast = lazy(() => import('./component/OrderListLast'));
const OrderListPayment = lazy(() => import('./component/OrderListPayment'));
const OrderListRemember = lazy(() => import('./component/OrderListRemember'));
const OrderListRepair = lazy(() => import('./component/OrderListRepair'));
const OrderListValidate = lazy(() => import('./component/OrderListValidate'));

export function OrderList() {
  const isChilderic = useUserIsChilderic();
  const hasSomeRole = useUserHasSomeRole();
  const resellerHasRole = useResellerHasRole();
  const hasOrder = resellerHasRole(ResellerRole.ORDER);

  const [tab, setTab] = useState<ETab>(() => {
    if (!hasOrder) return ETab.Childeric;
    if (hasSomeRole(UserRole.Admin, UserRole.AdminReseller)) return ETab.Validate;
    return ETab.Draft;
  });

  return (
    <StyledOrderList
      elevation={3}
      sx={{ width: '100%', overflow: 'hidden', minHeight: '588px', position: 'relative' }}
    >
      <Tabs
        value={tab}
        sx={{ mb: 2 }}
        variant="scrollable"
        scrollButtons="auto"
        onChange={(_e, t: ETab) => {
          setTab(t);
        }}
      >
        {hasOrder && hasSomeRole(UserRole.Admin, UserRole.AdminReseller) && (
          <TabValidate value={ETab.Validate} />
        )}
        {hasOrder && hasSomeRole(UserRole.Admin, UserRole.AdminReseller, UserRole.Commercial) && (
          <TabDraft value={ETab.Draft} />
        )}
        {hasOrder && hasSomeRole(UserRole.Admin, UserRole.AdminReseller, UserRole.Commercial) && (
          <TabLast value={ETab.Last} />
        )}
        {!isChilderic && hasSomeRole(UserRole.AdminReseller) && (
          <TabChildericOrder value={ETab.Childeric} />
        )}
        {hasSomeRole(UserRole.Admin, UserRole.AdminReseller, UserRole.Commercial) && (
          <TabRepair value={ETab.Repair} />
        )}
        {hasOrder && hasSomeRole(UserRole.Commercial) && <TabHistory value={ETab.History} />}
        <TabRemember value={ETab.Remember} />
        {hasOrder && isChilderic && hasSomeRole(UserRole.Payment, UserRole.Commercial) && (
          <TabPayment value={ETab.Payment} />
        )}
      </Tabs>

      {hasOrder && tab === ETab.Draft && (
        <Suspense>
          <OrderListDraft />
        </Suspense>
      )}
      {hasOrder && tab === ETab.Validate && (
        <Suspense>
          <OrderListValidate />
        </Suspense>
      )}
      {hasOrder && tab === ETab.Last && (
        <Suspense>
          <OrderListLast />
        </Suspense>
      )}
      {tab === ETab.Repair && (
        <Suspense>
          <OrderListRepair />
        </Suspense>
      )}
      {!isChilderic && tab === ETab.Childeric && (
        <Suspense>
          <OrderListChildericOrder />
        </Suspense>
      )}
      {hasOrder && tab === ETab.History && (
        <Suspense>
          <OrderListHistory />
        </Suspense>
      )}
      {tab === ETab.Remember && (
        <Suspense>
          <OrderListRemember />
        </Suspense>
      )}
      {hasOrder && isChilderic && tab === ETab.Payment && (
        <Suspense>
          <OrderListPayment />
        </Suspense>
      )}
    </StyledOrderList>
  );
}
