import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MenuLevel1, ResellerRole, UserRole } from '@/enum';
import { useResellerHasRole, useUserHasSomeRole, useUserIsChilderic } from '@/hook/user';
import { I18nNs } from '@/util/i18n';

import { Level1Item } from '../../Level1Item/Level1Item';

export function Level1Order() {
  const { t } = useTranslation(I18nNs.Menu);
  const hasSomeRole = useUserHasSomeRole();

  const resellerHasRole = useResellerHasRole();
  const hasOrder = resellerHasRole(ResellerRole.ORDER);
  const isChilderic = useUserIsChilderic();

  const to = useMemo(() => {
    if (isChilderic) return '/order/list';
    if (!hasOrder) return '/order/childeric';
    if (hasSomeRole(UserRole.AdminReseller)) return '/order/childeric';
    return '/order/list';
  }, [isChilderic, hasOrder, hasSomeRole]);

  if (!hasSomeRole(UserRole.Commercial, UserRole.Admin, UserRole.AdminReseller)) return null;

  return <Level1Item label={t('menu.order')} to={to} color={MenuLevel1.Order} />;
}
